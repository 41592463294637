import {
  ADMISSION_PROCESS_ID_ENUM,
  APPLICANT_TYPE_ENUM,
  ApplicationStatus,
  ERROR,
  USER_TYPE,
  limit,
} from 'src/constant';
import React, { useCallback, useEffect, useState } from 'react';
import {
  useCreateApplication,
  useGetApplicationDetails,
} from 'src/graphql/applications/applications';

import { useHistory, useParams } from 'src/routes/routing.web';
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';
import { applicationFormSchema } from 'src/form-json/applicant/application-form';
import { componentMapper } from 'src/components/molecules/ddfElements';
import { isNative, isWeb } from 'src/constant/device';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useI18n } from 'src/i18n/hooks';
import { validatorMapper } from 'src/components/molecules/Forms/validatorMapper';
import { inviteDetailQuery, useGetAdmissionLinkByRequestId } from 'src/graphql/admission';
import { FormSchema } from 'src/components/molecules/Forms/types';
import ApplicationForm from './Form/index';
import navigationStrings from 'src/navigation/navigationStrings';
import { userInformation } from 'src/utils/manageState';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import styled from 'styled-components/native';
import { Icon } from 'src/components/atoms/Icon/Icon';
import HeaderOne from 'src/components/atoms/Text/HeaderOne';
import MediumText from 'src/components/atoms/Text/MediumText';
import ERROR_MSG from 'src/constant/error';
import { showNativeError } from 'src/components/molecules/NotificationWrapper';
import { DropdownOptionsType } from 'src/types';
import { AdmissionDetailObj, CreateAdmObjType, DocumentObject, UserDocumentOutput } from './types';
import { graphqlQuery } from 'src/graphql/util';
import HeaderThree from 'src/components/atoms/Text/HeaderThree';
import MobileHeader from 'src/components/hoc/MobileHeader';
import { colors, fontWeight } from 'src/styles/theme/styles';
import { fileValidationConfig } from 'src/form-json/user-details/util';
import validatorTypes from '@data-driven-forms/react-form-renderer/validator-types';

export default function ApplicationFormViewer({
  navigation,
  route,
}: {
  navigation?: any;
  route?: { params: { applId?: string; requestId: string, capId: string; inviteCode: string } };
}) {
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const history = useHistory();
  const { setAlertDetails } = useAlertSystem();
  const [initialValues, setInitialValues] = useState({});
  const [changesAllowed, setChangesAllowed] = useState<boolean>(!editMode);
  const [inHouseAllowed, setInHouseAllowed] = useState<boolean>(false);
  const [applicantType, setApplicantType] = useState<string>('');
  const [admissionData, setAdmissionData] = useState<any>();
  const [formSchema, setFormSchema] = useState<FormSchema>();
  const [subgrpOptions, setSubGrpOptions] = useState<string[]>([]);
  const [instituteOptions, setInstituteOptions] = useState<DropdownOptionsType[]>([]);
  const [selectedSubGrp, setSelectedSubGrp] = useState<string>('');
  const [capId, setCapId] = useState<string>('');
  const [invalidInviteCode, setInvalidInviteCode] = useState<boolean>(false);
  const [admissionProcessId, setAdmissionProcessId] = useState<string>('');
  const [removedDocsId, setRemovedDocsId] = useState<string[]>([]);
  const currentUserObject = userInformation();

  let urlApplId: string;
  let reqId: string;
  let rcapId: string;
  let invCode: string;
  let editMode: boolean;
  const pathname: string = isWeb ? location?.pathname : '';
  if (isWeb) {
    const { urlApplicationId, requestId, capId, inviteCode } = useParams<{
      urlApplicationId: string;
      requestId: string;
      capId: string;
      inviteCode: string;
    }>();
    urlApplId = urlApplicationId;
    editMode =
      Boolean(pathname && pathname.startsWith('/application/edit/') && urlApplId?.length > 0);
    reqId = requestId;
    rcapId = capId;
    invCode = inviteCode;
  } else {
    if (route?.params) {
      if (route.params?.applId) {
        urlApplId = route.params.applId;
        editMode = true;
      } else {
        urlApplId = '';
        editMode = false;
        reqId = route.params.requestId;
        invCode = route.params.inviteCode;
      }
    }
  }

  const { createApplication } = useCreateApplication();
  const { getApplicationDetails, applicationDetailsData, refetchApplicationDetails } =
    useGetApplicationDetails();
  const {
    getAdmissionLinkByRequestId,
    admissionLinkData,
    admissionLinkError,
    admissionLinkLoading,
  } = useGetAdmissionLinkByRequestId();

  useEffect(() => {
    if (!editMode) {
      const initialValue = isWeb ? { label: "No", value: false } : false;

      setInitialValues({
        freeshipApplied: initialValue,
        inHouseApplied: initialValue,
      });
    }
  }, []);

  useEffect(() => {
    if (isWeb) {
      setHeading([
        {
          text: applicantType
            ? editMode
              ? t('edit-application.label')
              : t('add-application.label')
            : ' ',
          url: '',
        },
      ]);
    }
  }, [applicantType]);

  useEffect(() => {
    if (editMode && urlApplId) {
      getApplicationDetails({ variables: { id: urlApplId } });
    }
  }, [editMode]);

  useEffect(() => {
    if (applicationDetailsData?.application) {
      const { status } = applicationDetailsData.application;
      const changesAreAllowed = [ApplicationStatus.INCOMPLETE, ApplicationStatus.REJECTED].includes(
        status,
      );
      setChangesAllowed(changesAreAllowed);
      loadEditData(applicationDetailsData.application);
    }
  }, [applicationDetailsData]);

  useEffect(() => {
    if (invCode && (isNative || pathname.includes('invite'))) {
      handleFetchInviteDetail();
    }
  }, [invCode]);

  useEffect(() => {
    if (reqId && (isNative || pathname.includes('add'))) {
      getAdmissionLinkByRequestId({ variables: { requestId: reqId } });
    }
  }, [reqId]);

  useEffect(() => {
    if (rcapId && (isNative || pathname.includes('add'))) {
      setCapId(rcapId);
    }
  }, [rcapId]);

  useEffect(() => {
    if (admissionLinkData && admissionLinkData?.admissionLink) {
      handleSetAdmissionData(admissionLinkData?.admissionLink, true);
    }
  }, [admissionLinkData]);

  useEffect(() => {
    if (admissionProcessId) {
      if (currentUserObject?.userType !== USER_TYPE.STUDENT) {
        const formSchema = applicationFormSchema(
          admissionProcessId,
          [],
          () => { },
          currentUserObject?.userType,
          instituteOptions,
          capId,
        )
        getDocumentsData(formSchema);
      }
    }
  }, [admissionProcessId]);

  useEffect(() => {
    if (subgrpOptions && subgrpOptions.length > 0) {
      const formSchema = applicationFormSchema(
        admissionProcessId,
        subgrpOptions,
        handleSubGrpChange,
        currentUserObject?.userType,
        [],
        capId,
      )
      getDocumentsData(formSchema);
    }
  }, [subgrpOptions]);

  useEffect(() => {
    if (capId && !editMode) {
      setInitialValues({ capId: capId });
    }
  }, [capId, editMode]);

  function handleRemoveDocument(id: string) {
    if (id) {
      setRemovedDocsId((prev) => ([...prev, id]));
    }
  }

  async function getDocumentsData(formSchema: any) {
    const newData: any = { ...formSchema };
    const requiredDocuments = applicationDetailsData?.application?.documents || admissionData?.documents;
    if (requiredDocuments && requiredDocuments.length > 0) {
      const documents = requiredDocuments.map((item: any) => {
        const requiredDetail = item?.requiredDetail;
        return {
          name: `${requiredDetail?.docType?.id}_${requiredDetail?.allowedPlace}_DOC`,
          label: `${requiredDetail?.docType?.name}${requiredDetail?.required ? '*' : ''}`,
          category: requiredDetail?.docType?.category,
          component: 'file-upload',
          fileAtfield: `${requiredDetail?.docType?.id}_${requiredDetail?.allowedPlace}_DOC`,
          isRequired: requiredDetail?.required,
          ...(requiredDetail?.required
            ? { required_error: `${requiredDetail?.docType?.name?.split('*')[0]} is required` }
            : {}),
          type: 'file',
          compact: true,
          validate: [
            ...fileValidationConfig,
            ...(requiredDetail?.required
              ? [
                  {
                    type: validatorTypes.REQUIRED,
                    message: `${requiredDetail?.docType?.name?.split('*')[0]} is required`,
                  },
                ]
              : []),
          ],
          documentField: `${requiredDetail?.docType?.id}_${requiredDetail?.allowedPlace}_DOC`,
          customFieldId: true,
          customHandleRemoveDocument: handleRemoveDocument,
        }
      });

      if (documents && documents.length) {
        newData.fields = [...newData.fields, ...documents];
      }
    }
    setFormSchema(newData);
  }

  function handleSetAdmissionData(
    details: AdmissionDetailObj,
    fetchDocFromAdmission: boolean = false,
  ) {
    let documents;
    if (fetchDocFromAdmission) {
      documents = details?.admission?.requiredDocuments?.map((item: any) => ({
        document: null,
        requiredDetail: item,
      }));
    } else {
      documents = details?.documents;
    }
    setAdmissionData({
      inHouseApplicantAllowed: details?.admission?.inHouseApplicantAllowed,
      university: details?.university?.name,
      institute: details?.institute?.name,
      course: details?.course?.alias,
      class: details?.class?.alias,
      subjectGroup: details?.subjectGroup,
      batch: details?.batch?.alias,
      qualifications: details?.admission?.criteria?.qualifications,
      lastDate: details?.admission?.lastDate?.substring(
        0,
        details?.admission?.lastDate?.indexOf(','),
      ),
      fees: getTotalPayableFee(details?.admission?.subjectGroups, details?.subjectGroup?.id),
      applicantType: details?.applicationType || details?.applicantType,
      id: details?.admission?.id,
      admissionProcessId: details?.admission?.askAdmissionProcessId,
      ...(details?.admission?.applicationFeeRequired
        ? { applicationFees: details?.admission?.applicationFeeDetail?.feeAmount }
        : {}),
      documents: documents || [],
    });

    setApplicantType(details?.applicationType || details?.applicantType || '');
    setAdmissionProcessId(details?.admission?.askAdmissionProcessId);
    const isInHouse = !!details?.admission?.inHouseApplicantAllowed;
    setInHouseAllowed(isInHouse);
    if (isInHouse) {
      setInstituteOptions(details?.admission?.inHouseInstitutes);
    }
  }

  async function handleFetchInviteDetail() {
    const response = await graphqlQuery(inviteDetailQuery, { inviteCode: invCode });
    if (response?.data?.inviteDetail) {
      const responseData = response.data.inviteDetail;
      if (
        responseData?.applicationType === APPLICANT_TYPE_ENUM[0] &&
        responseData?.applicationStatus !== ApplicationStatus.INCOMPLETE &&
        isWeb
      ) {
        history.push('/dashboard');
      } else {
        setCapId(responseData?.capId);
        handleSetAdmissionData(responseData);
        if (responseData?.documents) {
          const docInitialValues = loadExistingDocuments(responseData.documents);
          if (docInitialValues && Object.keys(docInitialValues).length) {
            setInitialValues({ ...docInitialValues });
          }
        }
      }
    } else {
      setInvalidInviteCode(true);
    }
  }

  function handleAdmissionDataClear() {
    setAdmissionData((prev: any) => ({
      ...prev,
      fees: '-',
    }));
  }

  function handleSubGrpChange(subGrpId: string) {
    if (subGrpId) {
      const subGrp = applicationDetailsData?.application?.admission?.subjectGroups.find(
        (item: any) => item.subjectGroup.id === subGrpId,
      );
      setAdmissionData((prev: any) => ({
        ...prev,
        fees: subGrp?.fee?.feeBooks?.reduce((acc, { amount }) => acc + amount, 0) ?? '-',
      }));
      setSelectedSubGrp(subGrpId);
    } else {
      handleAdmissionDataClear();
    }
  }

  function prefillInhouseInstitute(instID: string, allInstitutes: DropdownOptionsType[]) {
    if (instID && allInstitutes?.length > 0) {
      return allInstitutes.find(e => e.value === instID);
    }
  }

  function loadExistingDocuments(applicationDataDocuments: UserDocumentOutput[]) {
    const docInitialValues: any = {};
    applicationDataDocuments?.forEach((item: any) => {
      const requiredDetail = item?.requiredDetail;
      if (item?.document) {
        docInitialValues[`${requiredDetail?.docType?.id}_${requiredDetail?.allowedPlace}_DOC`] =
          item.document;
      }
    });
    return docInitialValues;
  }

  function loadEditData(apiData: any) {
    const admission = apiData?.admission;
    const inHouseAllowed = admission?.inHouseApplicantAllowed;
    const isCAPType = admission?.askAdmissionProcessId === ADMISSION_PROCESS_ID_ENUM[0];
    const isUniversityType = admission?.askAdmissionProcessId === ADMISSION_PROCESS_ID_ENUM[2];
    const docInitialValues = loadExistingDocuments(applicationDetailsData?.application?.documents);
    setInHouseAllowed(inHouseAllowed);
    setAdmissionData({
      inHouseApplicantAllowed: inHouseAllowed,
      university: admission?.university?.name,
      institute: admission?.institute?.name,
      course: admission?.course?.alias,
      class: admission?.class?.alias,
      subjectGroup: apiData?.subjectGroup,
      batch: admission?.batch?.alias,
      qualifications: admission?.criteria?.qualifications,
      lastDate: admission?.lastDate?.substring(0, admission?.lastDate?.indexOf(',')),
      fees: getTotalPayableFee(admission?.subjectGroups, apiData?.subjectGroup?.id),
      applicantType: apiData?.applicantType,
      id: admission?.id,
      admissionProcessId: apiData?.admission?.askAdmissionProcessId,
      ...(admission?.applicationFeeRequired ? { applicationFees: admission?.applicationFeeDetail?.feeAmount } : {}),
    });

    setInitialValues({
      capId: isCAPType && apiData?.cap?.capId ? apiData.cap.capId : null,
      document: isCAPType && apiData?.cap?.document ? apiData.cap.document : null,
      universityApplicationDoc:
        isUniversityType && apiData?.universityApplicationDoc
          ? apiData.universityApplicationDoc
          : null,
      inHouseApplied: isWeb
        ? { label: apiData?.inHouseApplied ? 'Yes' : 'No', value: apiData?.inHouseApplied }
        : apiData?.inHouseApplied,
      inHouseDetailInstituteCode: apiData?.inHouseDetail && prefillInhouseInstitute(apiData.inHouseDetail?.instituteCode, admission?.inHouseInstitutes),
      inHouseDetailRollNo: apiData?.inHouseDetail && apiData.inHouseDetail?.rollNo,
      freeshipApplied: isWeb
        ? { label: apiData?.freeshipApplied ? 'Yes' : 'No', value: apiData?.freeshipApplied }
        : apiData?.freeshipApplied,
      ...(isUniversityType && apiData?.universityApplicationId
        ? { universityApplicationNumber: apiData.universityApplicationId }
        : inHouseAllowed
          ? {
            inHouseDetailRollNo: apiData?.inHouseDetail?.rollNo,
            inHouseDetailInstituteCode: isWeb
              ? {
                label: admission?.inHouseInstitutes?.find(
                  (e: { value: string }) =>
                    e.value === apiData?.inHouseDetail?.instituteCode
                )?.label,
                value: apiData?.inHouseDetail?.instituteCode,
              }
              : apiData?.inHouseDetail?.instituteCode,
          }
          : {}),
      ...(docInitialValues && Object.keys(docInitialValues).length ? { ...docInitialValues } : {}),
    });
    if (apiData?.cap?.capId) {
      setCapId(apiData?.cap?.capId);
    }
    setApplicantType(apiData?.applicantType);
    setAdmissionProcessId(apiData?.admission?.askAdmissionProcessId);
    if (currentUserObject?.userType === USER_TYPE.STUDENT) {
      const subGrps = admission?.subjectGroups?.map((item: any) => ({
        label: item.subjectGroup.name,
        value: item.subjectGroup.id,
      }));
      setSubGrpOptions(subGrps);
      if (apiData?.subjectGroup) {
        setSelectedSubGrp(apiData?.subjectGroup?.id);
        setInitialValues({
          subjectGroup: isWeb
            ? { label: apiData.subjectGroup.name, value: apiData.subjectGroup.id }
            : apiData.subjectGroup.id,
          ...(docInitialValues && Object.keys(docInitialValues).length ? { ...docInitialValues } : {}),
        });
      }
    }

    if (inHouseAllowed) {
      setInstituteOptions(admission?.inHouseInstitutes);
    }
  }


  async function handleOnApply(data: any) {
    if (!changesAllowed) {
      handleNextRedirect();
    } else {
      let documents: DocumentObject[] = [];
      for (let key of Object.keys(data)) {
        if (key.endsWith('_DOC') && data[key]) {
          const element = formSchema?.fields?.find((item: any) => item?.name === key);
          const elementData = element?.name?.split('_');
          const ref = applicationDetailsData?.application?.documents.find(
            (e: any) =>
              elementData &&
              e?.requiredDetail?.docType?.id === elementData[0] &&
              e?.requiredDetail?.allowedPlace === `${elementData[1]}_${elementData[2]}`,
          );

          if (ref && !data[key]?.id) {
            const req_doc = {
              ...data[key],
              typeRef: ref?.requiredDetail?.docType?.id,
              ...(ref?.document?.id ? { id: ref.document.id } : {}),
            };
            documents.push(req_doc);
          }
        }
      }

      const createAdmObj: CreateAdmObjType = {
        ...(documents && documents.length > 0 ? { documents } : {}),
        admission: admissionData?.id,
        applicantType: admissionData?.applicantType,
        subjectGroup:
          currentUserObject?.userType === USER_TYPE.STUDENT
            ? selectedSubGrp
            : admissionData?.subjectGroup?.id,
      };
      if (currentUserObject?.userType === USER_TYPE.APPLICANT) {
        createAdmObj['freeshipApplied'] = data?.freeshipApplied ? (
          isWeb ? data?.freeshipApplied?.value : data?.freeshipApplied
        ) : false;
      }
      if (admissionData?.admissionProcessId === ADMISSION_PROCESS_ID_ENUM[2]) {
        createAdmObj["universityApplicationId"] = data?.universityApplicationNumber;
        createAdmObj["universityApplicationDoc"] = data?.universityApplicationDoc?.id
          ? {
            category: data?.universityApplicationDoc?.category,
            id: data?.universityApplicationDoc?.id,
          }
          : data?.universityApplicationDoc
      }
      const applyingInhouseQuota = inHouseAllowed && (isWeb ? data.inHouseApplied.value : data.inHouseApplied)
      if (applyingInhouseQuota) {
        createAdmObj['inHouseApplied'] = isWeb ? data.inHouseApplied.value : data.inHouseApplied;
        createAdmObj['inHouseDetail'] = {
          instituteCode: isWeb ? data?.inHouseDetailInstituteCode?.value : data?.inHouseDetailInstituteCode,
          rollNo: data?.inHouseDetailRollNo
        };
      }
      if (
        Object.keys(data).length > 0 &&
        admissionData?.admissionProcessId === ADMISSION_PROCESS_ID_ENUM[0]
      ) {
        createAdmObj['capDetail'] = {
          capId: data.capId,
          ...(data?.document?.id
            ? {
              document: {
                category: data?.document?.category,
                id: data?.document?.id,
              },
            }
            : { document: data?.document }),
        };
      }
      if (editMode) {
        createAdmObj['id'] = urlApplId;
      }

      try {
        const createApplicationData = await createApplication({
          variables: {
            payload: createAdmObj,
            ...(removedDocsId && removedDocsId?.length
              ? { remove: { documents: removedDocsId } }
              : {}),
          },
        });
        if (createApplicationData?.data) {
          getApplicationDetails({
            variables: { id: createApplicationData.data.createApplication.id },
          });
          handleNextRedirect(createApplicationData.data.createApplication.id);
        }
      } catch (error: any) {
        const errorMsg = error.message.includes('Application already exists')
          ? ERROR_MSG.DUPLICATE_APPLICATION
          : error.message;
        if (isWeb) {
          setAlertDetails({ message: errorMsg, level: ERROR });
        } else {
          showNativeError(errorMsg);
        }
      }
    }
  }

  function handleCancel() {
    if (currentUserObject?.userType === USER_TYPE.STUDENT) {
      history.push(`/application/list/limit/${limit}/page/1`);
    } else {
      history.push(`/dashboard`);
    }
    setSelectedSubGrp('');
  }

  function handleNextRedirect(createdApplId?: string) {
    const applicationId = createdApplId || urlApplId;
    if (isWeb) {
      history.push(`/application/${applicationId}/qualifications`);
    } else {
      navigation.navigate(navigationStrings.EDUCATION_DETAILS, {
        applId: applicationId,
      });
    }
  }

  function getTotalPayableFee(subjectGroups: any, subGrpId?: string) {
    const subjectGroup = subjectGroups?.find((item: any) => item.subjectGroup.id === subGrpId);
    return subjectGroup?.fee?.feeBooks?.reduce((acc, { amount }) => acc + amount, 0) ?? '-';
  }

  const FormTemplate = useCallback(() => {
    const { handleSubmit } = useFormApi();
    return (
      <ApplicationForm
        admissionData={admissionData}
        formFields={formSchema?.fields}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        editMode={editMode}
        navigation={navigation || null}
        admissionLinkError={admissionLinkError}
        admissionLinkLoading={admissionLinkLoading}
        getTotalPayableFee={getTotalPayableFee}
        userType={currentUserObject?.userType}
      />
    );
  }, [formSchema, admissionData]);

  return (
    <>
      {formSchema && (
        <FormRenderer
          componentMapper={componentMapper}
          schema={formSchema}
          FormTemplate={FormTemplate}
          validatorMapper={validatorMapper}
          onSubmit={handleOnApply}
          initialValues={initialValues}
        />
      )}
      {isWeb ? (
        invalidInviteCode ? (
          <LoadContentWrapper>
            <ContainerWrapper>
              <HeaderThree value={t('error.invalidInviteCode.text')} />
            </ContainerWrapper>
          </LoadContentWrapper>
        ) : (
          Boolean((pathname.includes('invite') && !reqId) || (pathname.includes('add') && reqId)) &&
          admissionLinkError?.message &&
          (admissionLinkError.message === 'Invalid Admission Link' ? (
            history.push('/404')
          ) : (
            <LoadContentWrapper>
              <ContainerWrapper>
                <Icon name="link-closed" />
                <ContentWrapper>
                  <HeaderOne value={t('admissionNotAvailable.text')} />
                  <MediumText value={t('admissionClosed.info.text')} />
                </ContentWrapper>
              </ContainerWrapper>
            </LoadContentWrapper>
          ))
        )
      ) : (
        admissionLinkError?.message && (
          <>
            <MobileHeader
              label={t('add-application.label')}
              showSave={false}
              showEdit={false}
              navigation={navigation}
            />
            <NativeContainerWrapper>
              <HeaderThree
                value={
                  admissionLinkError.message === t('invalidAdmissionLink.text')
                    ? t('invalidAdmissionLink.text')
                    : t('admissionClosed.info.text')
                }
                color={colors.secondaryText}
                fontSize={24}
                fontWeight={fontWeight[600]}
                numberOfLines={3}
              />
            </NativeContainerWrapper>
          </>
        )
      )}
    </>
  );
}

const ContainerWrapper = styled.View`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.View`
  margin-top: 15px;
`;

const NativeContainerWrapper = styled(ContainerWrapper)`
  padding-horizontal: 12px;
`;